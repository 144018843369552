
import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";

import whatStyle from "assets/jss/material-kit-react/views/landingPageSections/whatStyles";

import { UserContext } from "context";

const useStyles = makeStyles(whatStyle);

export default function SectionTabs() {

  const { data } = useContext(UserContext);

  const [tabArr, setTabArr] = useState([]);

  useEffect(() => {
    const tmp = [];
    data.selectedData.secondary_section.map((obj) => {
      tmp.push({
        tabName: obj.title,
        tabContent: (
          <p className={classes.textCenter}>{obj.description}</p>
        ),
      });
    });
    setTabArr(tmp);
  }, [data]);

  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div>
        <div id="nav-tabs">
          <GridContainer className={classes.section_container}>
            <GridItem xs={12} sm={12} md={6} className={classes.section_item}>
              <CustomTabs
                plainTabs
                headerColor="primary"
                tabs={tabArr}
                className={classes.section_tabs}
              />
            </GridItem>
            
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
