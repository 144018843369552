import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import styles from "assets/jss/material-kit-react/components/headerStyle.js";
import { purple } from "@material-ui/core/colors";

const whiteLogo = require("assets/img/icon/white_logo.svg").default;
const blackLogo = require("assets/img/icon/logo.svg").default;

const UnActiveButton = withStyles(() => ({
  root: {
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: 30,
    color: "#000",
    backgroundColor: "#fff",
    zIndex: 0,
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#fff",
      borderColor: "#fff",
    },
  },
}))(Button);

const ActiveButton = withStyles((theme) => ({
  root: {
    zIndex: 1,
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: 30,
    color: `white !important`,
    backgroundColor: "#612EF2",

    boxShadow: theme.shadows[5],
    
    "&:hover": {
      boxShadow: theme.shadows[10],

      backgroundColor: "#612EF2",
    },
  },
}))(Button);

const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(whiteLogo);
  const [logo, setLogo] = React.useState(whiteLogo);
  const [active, setActive] = React.useState(true);
  const [scrollFlag, setScrollFlg] = React.useState(false);

  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
      setLogo(blackLogo);
      setScrollFlg(true);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
      setLogo(whiteLogo);
      setScrollFlg(false);
    }
  };
  const { color, leftLinks, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
  });
  const brandComponent = (
    <Button className={classes.title} style={{
      width: '5rem',
      padding: 0
    }}>
      <img src={logo} width="210px" height="36px" />
    </Button>
  );
  return (
    <AppBar className={appBarClasses}>
      <Toolbar className={classes.container}>
        {leftLinks !== undefined ? brandComponent : null}
        <div className={classes.flex}>
          {leftLinks !== undefined ? (
            <Hidden smDown implementation="css">
              {leftLinks}
            </Hidden>
          ) : (
            brandComponent
          )}
        </div>
        {scrollFlag && (
          <div>
            <ActiveButton
              variant="contained"
              color="primary"
              target="_blank"
              style={{ paddingRight: 40 }}
              href="https://market.flogi.co.uk/"
            >
              Demo
            </ActiveButton>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}

Header.defaultProp = {
  color: "white",
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
    ]).isRequired,
  }),
};
