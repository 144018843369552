import React, { useEffect, useState, useContext } from "react";
// nodejs library that concatenates classes
// import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
// import HeaderLinks from "components/Header/HeaderLinks.js";

// Sections for this page
import WatchSection from "./Sections/WatchSection.js";
import WhySection from "./Sections/WhySection.js";
import WhatSection from "./Sections/WhatSection.js";
import HowSection from "./Sections/HowSection.js";
import PacksliderSection from "./Sections/PacksliderSection.js";
import PackageSection from "./Sections/PackageSection.js";
import ContactSection from "./Sections/ContactSection.js";

import { UserContext } from "context";

const dashboardRoutes = [];
const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: "12",
    color: "#FFFFFF",

    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
    width: "100%",

    "@media (min-width: 576px)": {
      maxWidth: "540px",
    },
    "@media (min-width: 768px)": {
      maxWidth: "720px",
    },
    "@media (min-width: 992px)": {
      maxWidth: "960px",
    },
    "@media (min-width: 1200px)": {
      maxWidth: "1140px",
    },
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0",
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    margin: "-20px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    "@media(max-width: 768px)": {
      margin: "-20px 10px 0px",
    }
  },
  parallexRightTxt: {
    marginTop: 450,
    alignSelf: "center",
    textAlign: "left",
    color: "#000",
    fontSize: "26px",
    fontWeight: 400,

    "@media (max-width: 768px)": {
      fontSize: "22px",
      marginTop: 150,
      textAlign: "center",
    },
  },
  bodyContainer:{
    backgroundColor: "#eeeeee"
  },
  grid_container: {
    
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse !important',
    }
  }
}));

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const { data } = useContext(UserContext);

  const text_data = [
    "Flow and Logistics for eCommerce",
  ];

  return (
    <div className={classes.bodyContainer}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("assets/img/icon/logo.svg").default} width="70%" height="70%"/>}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax filter>
        <div className={classes.container}>
          <Carousel
            autoPlay={false}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            showArrows={false}
            infiniteLoop={true}
          >
            {text_data.map((text, i) => (
              <GridContainer
                className={classes.grid_container}
                style={{ justifyContent: "space-between" }}
                key={i}
              >
                <GridItem xs={12} sm={7}>
                  <img
                    src={
                      data.defaultAPI + data.glb_state.Banner[0].Image[0].url
                    }
                    width="100%"
                    height="100%"
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={5}
                  style={{ padding: 0, alignItems: "center", display: "flex" }}
                >
                  <h4 className={classes.parallexRightTxt}>{text}</h4>

                </GridItem>
              </GridContainer>
            ))}
          </Carousel>
        </div>
      </Parallax>
      <div>
        <WatchSection />
        <WhySection />
        {/* <WhatSection /> */}
        <HowSection />
        {/* <PacksliderSection /> */}
        {data.selectedPackage != 6 && <PackageSection />}
        <ContactSection />
      </div>
      <Footer />
    </div>
  );
}
