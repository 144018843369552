import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import GridContainer from "components/Grid/GridContainer.js";
import Grid from '@material-ui/core/Grid';
import GridItem from "components/Grid/GridItem.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";;

import { UserContext } from "context";

const useStyles = makeStyles((theme) => ({
  section: {
    background: "#EEEEEE",
    padding: "70px 0",
  },
  container: {
    width: "100%",
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",

    "@media (min-width: 576px)": {
      maxWidth: "540px",
    },
    "@media (min-width: 768px)": {
      maxWidth: "720px",
    },
    "@media (min-width: 992px)": {
      maxWidth: "960px",
    },
    "@media (min-width: 1200px)": {
      maxWidth: "1140px",
    },
  },
  textCenter: {
    textAlign: "center",
  },
  title: {
    fontSize: 32,
    fontWeight: 600,
    textAlign: "center",
    maxWidth: 500,
    justfyContent: "center",
    margin: "auto",
  },
  grid_item: {
    marginTop: '70px',

    '&:first-child': {
      display: 'flex',
      justifyContent: 'center',
    }
  }
}));

export default function WhySection() {
  const context = useContext(UserContext);
  const { data } = context;

  const [tabArr, setTabArr] = useState([]);

  useEffect(() => {
    const tmp = [];
    data.glb_state.how_section.map((obj) => {
      tmp.push({
        tabName: obj.primary_title,
        tabContent: (
          <p className={classes.textCenter}>{obj.primary_description}</p>
        ),
      });
    });
    setTabArr(tmp);
  }, []);

  const onActive = (v) => {
    context.setData({...data, selectedData: data.glb_state.how_section[v]})
  }

  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div id="nav-tabs">
          <h3 className={classes.title}>{data.glb_state.how_title}</h3>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6} className={classes.grid_item}>
              <CustomTabs plainTabs headerColor="primary" tabs={tabArr} onActive={onActive} />
            </GridItem>
            <GridItem xs={12} sm={12} md={6} className={classes.grid_item}>
              {data.selectedData.facts.map((s, i) => {
                    return(
                      <Grid container spacing={3} key={i}>
                      <Grid item xs={1}>
                        <CheckCircleIcon style={{color: "#FFC700"}}/>
                      </Grid>
                      <Grid item xs={11}>
                        <p>{s.fact}</p>
                      </Grid>
                    </Grid>
                    )
                  })}
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
