import { container } from "assets/jss/material-kit-react.js";

const whatStyle = {
  section: {
    background: "#612EF2",
    // padding: "70px 0",
  },
  textCenter: {
    textAlign: "center",
    color: "#ffffff !important"
  },
  section_container : {
    alignItems: "center",
    display: "flex",
  },
  section_tab: {
    width: "600px",
    height: "200px",
  },
  title: {
    fontSize: 32,
    fontWeight: 600,
    textAlign: "center",
    maxWidth: 500,
    justifyContent: "center",
    margin: "auto",
  },
};

export default whatStyle;